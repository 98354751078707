<template>
    <div class="my-withdraw-container">
      <!-- 余额 -->
      <div class="my-withdraw-cash-car">
        <p class="my-withdraw-cash-car-balance">2000.00</p>
        <p class="my-withdraw-cash-car-balance-tips">可提现金额</p>
      </div>
      <!-- 菜单 -->
      <div class="my-withdraw-menu">
        <ul>
          <li v-for="(item,index) in withdrawMenuData" :key="index" @click="clickMyWithdrawMenuHandler(index)">
            <p class="my-withdraw-menu-title" :style="{color:withdrawMenuIndex===index?'#F02233':''}">{{item}}</p>
          </li>
          <div class="my-withdraw-menu-slider" :style="{left:withdrawMenuSlider}"></div>
        </ul>
      </div>
      <!-- 表单 -->
      <div class="my-withdraw-info-setting">
        <ul>
          <li>
            <p class="my-withdraw-info-title">提现金额</p>
            <input class="my-withdraw-info-input" type="number" placeholder="请输入提现金额"/>
          </li>
          <li v-if="withdrawMenuIndex===0">
            <p class="my-withdraw-info-title">支付宝账号</p>
            <input class="my-withdraw-info-input" type="text" placeholder="请输入支付宝账号"/>
          </li>
          <li v-if="withdrawMenuIndex===0">
            <p class="my-withdraw-info-title">确认支付宝账号</p>
            <input class="my-withdraw-info-input" type="text" placeholder="再次输入支付宝账号"/>
          </li>
          <li v-if="withdrawMenuIndex===1">
            <p class="my-withdraw-info-title">银行卡卡号</p>
            <input class="my-withdraw-info-input" type="text" placeholder="请输入本人银行卡卡号"/>
          </li>
          <li v-if="withdrawMenuIndex===1">
            <p class="my-withdraw-info-title">开户行</p>
            <input class="my-withdraw-info-input" type="text" placeholder="请输入开户行名称"/>
          </li>
          <li>
            <p class="my-withdraw-info-title">真实姓名</p>
            <input class="my-withdraw-info-input" type="text" placeholder="请输入真实姓名"/>
          </li>
        </ul>
        <div class="my-withdraw-info-commit-btn">
            <p class="my-withdraw-info-commit-btn-text">提交申请</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:"MyWithdraw",
    data(){
      return {
        withdrawMenuIndex:0,
        withdrawMenuData:["支付宝","银行卡"]
      }
    },
    methods:{
      clickMyWithdrawMenuHandler(index){
        this.withdrawMenuIndex = index;
      }
    },
    computed:{
      withdrawMenuSlider(){
        return this.withdrawMenuIndex*100+4+'px';
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .my-withdraw-container{
      width: 1200px;
      background: #FFFFFF;
      margin-top: 1px;
      padding-top: 20px;
      padding-bottom: 30px;
      border-radius: 0px 0px 8px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .my-withdraw-cash-car{
        width: 280px;
        height: 150px; 
        background: linear-gradient( 270deg, #E73443 0%, #FF934D 100%);
        box-shadow: 0px 3px 10px 0px rgba(240,34,51,0.3);
        background-image: url(../../../../public/images/home/my_center_cash_car_bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        .my-withdraw-cash-car-balance{
          height: 30px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 30px;
          color: #FFFFFF;
          line-height: 30px;
          text-align: right;
          font-style: normal;
        }
        .my-withdraw-cash-car-balance-tips{
          margin-top: 6px;
          height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 16px;
          text-align: right;
          font-style: normal;
        }
      }
      .my-withdraw-menu{
        margin-top: 40px;
        width: 1200px;
        display: flex;
        justify-content: center;
        ul{
          list-style-type: none;
          padding: 0px;
          width: 208px;
          height: 36px;
          background: #F5F5F5;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          li{
            list-style: none;
            width: 100px;
            height: 28px;
            background: #F4F4F4;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .my-withdraw-menu-title{
              z-index: 99;
              height: 19px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #808080;
              line-height: 19px;
              text-align: right;
              font-style: normal;
            }
            &:hover .my-withdraw-menu-title{
              color: #F02233;
            }
          }
          .my-withdraw-menu-slider{
            z-index: 0;
            position: absolute;
            left: 4px;
            top: 4px;
            width: 100px;
            height: 28px;
            background: #FFFFFF;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .my-withdraw-info-setting{
        margin-top: 40px;
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        ul{
          width: 1200px;
          list-style-type: none;
          padding: 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          li{
            list-style: none;
            width:420px;
            height: 70px;
            margin-bottom: 30px;
            cursor: default;
            .my-withdraw-info-title{
              height: 14px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #555555;
              line-height: 14px;
              text-align: left;
              font-style: normal;
            }
            .my-withdraw-info-input{
              margin-top: 10px;
              width: 420px;
              height: 46px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #F0F0F0;
              outline: none;
              padding-left: 14px;
              &::placeholder{
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #CCCCCC;
                line-height: 14px;
                text-align: left;
                font-style: normal;
              }
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
              }
            }
            &:nth-child(2n){
              margin-left: 100px;
            }
          }
        }
        .my-withdraw-info-commit-btn{
          margin-top: 20px;
            width: 200px;
            height: 46px;
            background: #F02233;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .my-withdraw-info-commit-btn-text{
              height: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 14px;
              text-align: right;
              font-style: normal;
            }
          }
      }
  }
  </style>